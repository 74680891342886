import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';

export const getSchoolHistory = createAsyncThunk(
  'schoolHistory/getSchoolHistory',
  async ({ student, type }) => {
    try {
      const response = await api.get('academic-record', {
        params: {
          studentId: student.id,
          type,
        },
      });
      const { data } = await response.data;

      if (!data.length) {
        const create = await api.post('academic-record', {
          studentId: student.id,
          type,
        });
        const createData = await create.data.data;

        return {
          success: true,
          data: createData[0],
        };
      }

      return {
        success: true,
        data: data[0],
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const createSchoolHistory = createAsyncThunk(
  'schoolHistory/createSchoolHistory',
  async ({ student, type }) => {
    try {
      const response = await api.post('academic-record', {
        studentId: student.id,
        type,
      });
      const data = await response.data;

      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const generateSchoolHistory = createAsyncThunk(
  'schoolHistory/generateSchoolHistory',
  async ({ student, type }) => {
    try {
      const response = await api.post('academic-record', {
        studentId: student.id,
        type,
      });
      const data = await response.data;

      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const updateSchoolHistory = createAsyncThunk(
  'schoolHistory/updateSchoolHistory',
  async (history) => {
    try {
      const response = await api.patch(`academic-record/${history.id}`, {
        ...history,
      });
      const data = await response.data;

      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

const schoolHistorySlice = createSlice({
  name: 'schoolHistory',
  initialState: {
    selectedSchoolHistory: null,
    selectedStudent: null,
    recordType: null,
  },
  reducers: {
    setSliceValue(state, { payload }) {
      const { field, value } = payload;
      state[field] = value;
    },
  },
  extraReducers: {
    [getSchoolHistory.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        state.selectedSchoolHistory = payload.data;
      }
    },

    [createSchoolHistory.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        state.selectedSchoolHistory = payload.data;
      }
    },

    [updateSchoolHistory.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        state.selectedSchoolHistory = payload.data;
      }
    },
  },
});

export const { setSliceValue } = schoolHistorySlice.actions;

export default schoolHistorySlice.reducer;
